import React from 'react';
import { getTatStartRef } from '../../helpers';
import { logPageVisit } from '../../helpers/loggerWrapper';
import SimpleOverlay from '../SimpleOverlay';
import { WithTranslation, withTranslation } from 'react-i18next';
import ClickTracker from '../../helpers/ui-click-event';
import { DataService } from '../../services/DataService';
import { ERROR_TYPE_INVALID_LINK } from '../../helpers/constants';
import RealUserMonitoring from '../../helpers/real-user-monitoring';

type CombinedProps = WithTranslation;
/**
 * Component to display Invalid Link Page
 */
class InvalidLinkComponent extends React.Component<CombinedProps> {
  loggerStartTime = getTatStartRef();

  componentDidMount(): void {
    ClickTracker?.setComponentName?.('InvalidLinkComponent');
    RealUserMonitoring.logMessage('Redirected to invalid link', 'info', {
      message: 'Invalid link',
    });
    logPageVisit('InvalidLink', 'InvalidLinkComponent');
    DataService.sendErrorEncountered({
      error_encountered: ERROR_TYPE_INVALID_LINK,
    });
  }

  componentWillUnmount(): void {
    ClickTracker?.unsetComponentName?.();
  }

  render(): JSX.Element {
    return (
      <SimpleOverlay
        showHeader
        titleConfig={{
          primaryText: this.props.t('INVALID_LINK'),
          secondaryText: this.props.t('INVALID_LINK_MESSAGE_1'),
          tertiaryText: this.props.t('INVALID_LINK_MESSAGE_2'),
          titleSvg: 'invalid',
        }}
        showCrossButton={false}
      />
    );
  }
}

export default withTranslation()(InvalidLinkComponent);
