import React from 'react';
import { Snackbar, SnackbarContent } from '@material-ui/core';
import { DataService } from '../../services/DataService';
import { getCompatibilityChecks } from '../../helpers/compatibility';
import {
  getTatStartRef,
  getOperatingSystem,
  openChrome,
  reloadPage,
  getUserAgent,
} from '../../helpers';
import { log, LogLevel, logPageVisit } from '../../helpers/loggerWrapper';

import './BrowserNotSupported.css';
import InstructionSet, {
  InstructionConfig,
  ButtonConfig,
  buttonActionTypes,
} from '../InstructionBasedOverlay';
import theme from '../../theme';
import {
  ERROR_TYPE_COMPATIBILITY,
  IOS_VERSION,
  OS_NAME,
} from '../../helpers/constants';
import { WithTranslation, withTranslation } from 'react-i18next';
import { detect } from '../../helpers/browser-detection';
import RealUserMonitoring from '../../helpers/real-user-monitoring';

interface State {
  linkCopied: boolean;
}
interface Props {
  errorType: string;
}

const detectedbrowser = detect(getUserAgent());

type CombinedProps = Props & WithTranslation;

class BrowserNotSupported extends React.Component<CombinedProps, State> {
  checkChromeBrowser = detectedbrowser && detectedbrowser.name === 'chrome';

  checkChromeWebview =
    detectedbrowser && detectedbrowser.name === 'chrome-webview';

  loggerStartTime: number;

  iosCompatibility = getCompatibilityChecks().find(
    (element) => element.type === IOS_VERSION,
  );

  buttons: ButtonConfig[] = [];

  constructor(props: CombinedProps) {
    super(props);
    this.loggerStartTime = getTatStartRef();
    this.copyLink = this.copyLink.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.actionCallback = this.actionCallback.bind(this);
    this.getButtons = this.getButtons.bind(this);
    this.createInstructionArray = this.createInstructionArray.bind(this);
    this.getBrowserPrimaryText = this.getBrowserPrimaryText.bind(this);
    this.getBrowserSecondaryText = this.getBrowserSecondaryText.bind(this);
    this.handlePageVisibilityChange =
      this.handlePageVisibilityChange.bind(this);
    this.state = { linkCopied: false };
    this.buttons = this.getButtons();
  }

  handlePageVisibilityChange(): void {
    if (!document.hidden) {
      log(
        LogLevel.Info,
        {
          serviceCategory: 'Capture',
          service: 'PageVisibilityCallback',
          eventType: 'Executed',
          eventName: 'Foreground',
          component: 'AssistedVideoKYC',
          eventSource: 'logPageVisibilityCallback',
          publishToDLK: true,
          referenceID: DataService.getCapturePublicId(),
          referenceType: 'CaptureID',
        },
        {
          api: 'VisibilityChange',
          mdn_api: true,
          current_page: 'BrowserNotSUpported',
        },
      );

      reloadPage();
    }
  }

  componentDidMount(): void {
    document.addEventListener(
      'visibilitychange',
      this.handlePageVisibilityChange,
    );
    DataService.EndCommunications().then(
      // eslint-disable-next-line
      () => {},
      // eslint-disable-next-line
      () => {},
    );
    RealUserMonitoring.logMessage(
      'Redirected to browser not supported page',
      'info',
      {
        message: 'Browser not supported',
      },
    );
    logPageVisit('IncompatibleBrowser', 'BrowserNotSupported');
  }

  componentWillUnmount(): void {
    document.removeEventListener(
      'visibilitychange',
      this.handlePageVisibilityChange,
    );
  }

  handleClose(): void {
    this.setState({ linkCopied: false });
  }

  getBrowserPrimaryText(errorType: string): string {
    if (errorType === ERROR_TYPE_COMPATIBILITY) {
      if (this.iosCompatibility && !this.iosCompatibility.value) {
        return this.props.t('INCOMPATIBLE_DEVICE');
      }
      if (getOperatingSystem() === OS_NAME.iOS) {
        return this.props.t('BROWSER_INCOMPATIBLE_IOS_TITLE');
      }
    }
    if (getOperatingSystem() === OS_NAME.iOS) {
      return this.props.t('BROWSER_INCOMPATIBLE_IOS_TITLE');
    }
    if (this.checkChromeBrowser) {
      return this.props.t('CHROME_BROWSER_VERSION_INCOMPATIBLE_TITLE');
    }
    if (this.checkChromeWebview) {
      return this.props.t('CHROME_WEBVIEW_VERSION_INCOMPATIBLE_TITLE');
    }
    return this.props.t('INCOMPATIBLE_BROWSER_TITLE');
  }

  getBrowserSecondaryText(errorType: string): string {
    if (errorType === ERROR_TYPE_COMPATIBILITY) {
      if (this.iosCompatibility && !this.iosCompatibility.value) {
        return this.props.t('BROWSER_NOT_COMPATIBLE_SUBTITLE_IOS_BLACKLISTED');
      }
      if (getOperatingSystem() === OS_NAME.iOS) {
        return this.props.t('BROWSER_NOT_COMPATIBLE_SUBTITLE_IOS_BLACKLISTED');
      }
    }
    if (getOperatingSystem() === OS_NAME.iOS) {
      return this.props.t('BROWSER_INCOMPATIBLE_IOS_SUBTITLE');
    }

    if (this.checkChromeBrowser) {
      return this.props.t('CHROME_BROWSER_VERSION_INCOMPATIBLE_SUBTITLE');
    }
    if (this.checkChromeWebview) {
      return this.props.t('CHROME_WEBVIEW_VERSION_INCOMPATIBLE_SUBTITLE');
    }
    return this.props.t('INCOMPATIBLE_BROWSER_SUBTITLE');
  }

  getButtons(): ButtonConfig[] {
    const buttonArray: ButtonConfig[] = [];
    buttonArray.push({
      label: this.props.t('COPY_LINK_BUTTON_LABEL'),
      action: 'copy',
      text: this.checkChromeBrowser
        ? 'https://play.google.com/store/apps/details?id=com.android.chrome'
        : this.checkChromeWebview
        ? 'https://play.google.com/store/apps/details?id=com.google.android.webview'
        : window.location.href,
    });
    return buttonArray;
  }

  createInstructionArray(errorType: string): InstructionConfig[] {
    const instructionArrayForChrome: InstructionConfig[] = [
      { instruction: this.props.t('BROWSER_NOT_COMPATIBLE_CHROME_STEP_ONE') },
      { instruction: this.props.t('BROWSER_NOT_COMPATIBLE_CHROME_STEP_TWO') },
      { instruction: this.props.t('BROWSER_NOT_COMPATIBLE_CHROME_STEP_THREE') },
      { instruction: this.props.t('BROWSER_NOT_COMPATIBLE_CHROME_STEP_FOUR') },
      { instruction: this.props.t('BROWSER_NOT_COMPATIBLE_CHROME_STEP_FIVE') },
      { instruction: this.props.t('BROWSER_NOT_COMPATIBLE_CHROME_STEP_SIX') },
      { instruction: this.props.t('BROWSER_NOT_COMPATIBLE_CHROME_STEP_SEVEN') },
    ];
    const instructionArrayForChromeWebview: InstructionConfig[] = [
      {
        instruction: this.props.t(
          'BROWSER_NOT_COMPATIBLE_CHROME_WEBVIEW_STEP_ONE',
        ),
      },
      {
        instruction: this.props.t(
          'BROWSER_NOT_COMPATIBLE_CHROME_WEBVIEW_STEP_TWO',
        ),
      },
      {
        instruction: this.props.t(
          'BROWSER_NOT_COMPATIBLE_CHROME_WEBVIEW_STEP_THREE',
        ),
      },
      {
        instruction: this.props.t(
          'BROWSER_NOT_COMPATIBLE_CHROME_WEBVIEW_STEP_FOUR',
        ),
      },
      {
        instruction: this.props.t(
          'BROWSER_NOT_COMPATIBLE_CHROME_WEBVIEW_STEP_FIVE',
        ),
      },
      {
        instruction: this.props.t(
          'BROWSER_NOT_COMPATIBLE_CHROME_WEBVIEW_STEP_SIX',
        ),
      },
    ];
    const instructionArrayForNonChrome: InstructionConfig[] = [
      { instruction: this.props.t('BROWSER_NOT_COMPATIBLE_STEP_ONE') },
      { instruction: this.props.t('BROWSER_NOT_COMPATIBLE_STEP_TWO') },
      { instruction: this.props.t('BROWSER_NOT_COMPATIBLE_STEP_THREE') },
      { instruction: this.props.t('BROWSER_NOT_COMPATIBLE_STEP_FOUR') },
    ];
    const instructionArrayForBlacklistedIOSVersions: InstructionConfig[] = [
      { instruction: this.props.t('BROWSER_NOT_COMPATIBLE_STEP_ONE') },
      {
        instruction: this.props.t('BROWSER_NOT_COMPATIBLE_STEP_TWO'),
      },
    ];

    if (errorType === ERROR_TYPE_COMPATIBILITY) {
      if (this.iosCompatibility && !this.iosCompatibility.value) {
        return instructionArrayForBlacklistedIOSVersions;
      }
      return instructionArrayForNonChrome;
    }
    if (this.checkChromeBrowser) {
      return instructionArrayForChrome;
    }
    if (this.checkChromeWebview) {
      return instructionArrayForChromeWebview;
    }
    return instructionArrayForNonChrome;
  }

  actionCallback(action: buttonActionTypes): void {
    if (action === 'copy') {
      this.copyLink();
    } else if (action === 'open') {
      openChrome(
        detectedbrowser ? detectedbrowser.name === 'chrome-webview' : false,
      );
    }
  }

  copyLink(): void {
    log(LogLevel.Info, {
      serviceCategory: 'Capture',
      service: 'CompatibilityCheck',
      eventType: 'Clicked',
      eventName: 'CopyLink',
      component: 'BrowserNotSupported',
      eventSource: 'copyLink',
    });
    this.setState({ linkCopied: true });
  }

  render(): JSX.Element {
    const { linkCopied } = this.state;
    const { errorType } = this.props;
    return (
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <InstructionSet
          showHeader={false}
          titleConfig={{
            primaryText: this.getBrowserPrimaryText(errorType),
            secondaryText: this.getBrowserSecondaryText(errorType),
            titleSvg: 'browserNotSupported',
          }}
          instructions={this.createInstructionArray(errorType)}
          buttonConfig={this.buttons}
          actionCallback={this.actionCallback}
        />
        <Snackbar
          className="copy-link-snackbar"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={linkCopied}
          autoHideDuration={6000}
          onClose={this.handleClose}
          style={{ backgroundColor: theme().palette.success.main }}
        >
          <SnackbarContent
            aria-describedby="client-snackbar"
            message={
              <span id="client-snackbar">{this.props.t('COPY_LINK_CUE')}</span>
            }
            style={{
              backgroundColor: theme().palette.success.main,
              color: theme().palette.success.contrastText,
            }}
            className="copy-link-snackbar-text"
          />
        </Snackbar>
      </div>
    );
  }
}

export default withTranslation()(BrowserNotSupported);
