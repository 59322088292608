import React from 'react';
import { Button, Typography, CircularProgress } from '@material-ui/core';
import CopyToClipboard from 'react-copy-to-clipboard';
import { connect } from 'react-redux';
import { History, UnregisterCallback } from 'history';
import AppBar from '../AppBar';
import { createMarkup } from '../../helpers';
import './SimpleOverlay.css';
import { buttonActionTypes, ButtonConfig } from '../InstructionBasedOverlay';
import { AppState } from '../../store';
import { ClearOverlayAction } from '../../store/Error/actions';
import { DataService } from '../../services/DataService';
import theme from '../../theme';
import { RedirectUri } from '../../store/RedirectUri/types';
import { HeaderDetails } from '../Header/store/types';
import { WithTranslation, withTranslation } from 'react-i18next';

interface OwnProps {
  showHeader: boolean;
  titleConfig: {
    primaryText: string;
    secondaryText: string;
    tertiaryText?: string;
    titleSvg: string;
    displayLogo?: boolean;
    justifySecondaryText?: boolean;
  };
  headerConfig?: {
    appBackground: string;
    isErrorScreen: boolean;
    displayBackButton: boolean;
    displayExitButton?: boolean;
  };
  buttonConfig?: ButtonConfig[];
  bottomNote?: string;
  handleGoBack?: () => void;
  actionCallback?: (actionType: buttonActionTypes) => void;
  handleCrossBtn?: () => void;
  showCrossButton: boolean;
  history?: History;
  loaderConfig?: {
    displayLoader: boolean;
    loaderTimer: number;
  };
}

interface DispatchProps {
  ClearOverlayAction: typeof ClearOverlayAction;
}

interface StateProps {
  redirectUri: RedirectUri;
  headerDetails: HeaderDetails;
}

export interface Props extends OwnProps, DispatchProps, StateProps {}

interface State {
  displayLoader: boolean;
}

type CombinedProps = Props & WithTranslation;

class SimpleOverlay extends React.Component<CombinedProps, State> {
  unlisten: UnregisterCallback | undefined;

  constructor(props: CombinedProps) {
    super(props);
    this.onClick = this.onClick.bind(this);
    this.state = {
      displayLoader: props.loaderConfig
        ? props.loaderConfig.displayLoader
        : false,
    };
  }

  componentDidMount(): void {
    // Adding delayed timer to avoid listening to history change
    // that is imminent after RaiseError call to put up this dialog.
    // We are interested in listening to history change only
    // after the dialog has been completely rendered.
    const { history, loaderConfig, ClearOverlayAction } = this.props;
    setTimeout(() => {
      this.unlisten =
        history &&
        history.listen((location, action) => {
          if (action === 'POP') {
            ClearOverlayAction();
            this.unlisten && this.unlisten();
            history && history.goBack();
          }
        });
    }, 500);
    if (loaderConfig && loaderConfig.displayLoader) {
      setTimeout(() => {
        this.setState({ displayLoader: false });
      }, loaderConfig.loaderTimer);
    }
  }

  componentWillUnmount(): void {
    this.unlisten && this.unlisten();
  }

  onClick(action: buttonActionTypes): void {
    const { actionCallback } = this.props;
    actionCallback && actionCallback(action);
  }

  getTitle(svg: string): JSX.Element {
    const { headerDetails, showHeader, titleConfig } = this.props;
    const { displayLoader } = this.state;
    return (
      <div
        className="primary-title-div"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: !showHeader ? '16%' : '0%',
        }}
      >
        {titleConfig.displayLogo && (
          <img
            src={DataService.buildUrl(headerDetails.logo, 'GET')}
            alt={headerDetails.logo_alt}
            className="simple-overlay-logo"
          />
        )}
        {titleConfig.titleSvg && !titleConfig.displayLogo && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom: '4%',
            }}
          >
            {DataService.getSvg(svg)}
          </div>
        )}
        {titleConfig.primaryText && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'center',
            }}
          >
            <Typography
              variant="h6"
              gutterBottom
              style={{
                fontWeight: 'bold',
                color: theme().palette.secondary.contrastText,
              }}
            >
              {titleConfig.primaryText}
            </Typography>
          </div>
        )}
        {titleConfig.secondaryText && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: titleConfig.justifySecondaryText
                ? 'justify'
                : 'center',
              width: '85vw',
              maxWidth: '400px',
              marginTop: '5px',
            }}
          >
            {this.getSecondaryText()}
          </div>
        )}
        {titleConfig.tertiaryText && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'center',
            }}
          >
            <Typography variant="body1" gutterBottom>
              {titleConfig.tertiaryText}
            </Typography>
          </div>
        )}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          className="progressRoot"
        >
          {displayLoader && (
            <>
              <Typography
                variant="h5"
                component="h3"
                className="textStyle"
                gutterBottom
              >
                {this.props.t('REDIRECTING')}
              </Typography>
              <CircularProgress />
            </>
          )}
        </div>
      </div>
    );
  }

  getSecondaryText(): JSX.Element {
    const { titleConfig } = this.props;
    const secondaryTextArray = titleConfig.secondaryText
      .replace(/\.\s+/g, '.|')
      .split('|');
    return (
      <div>
        {secondaryTextArray.map((element: string) => (
          <Typography
            variant="body1"
            key={element}
            gutterBottom
            dangerouslySetInnerHTML={createMarkup(element)}
          />
        ))}
      </div>
    );
  }

  getFooter(buttons: ButtonConfig[]): JSX.Element {
    const { actionCallback } = this.props;
    return (
      <div className="proceedButton">
        <div className="buttonGroup">
          {buttons.map((element: ButtonConfig, index: number) => {
            if (index < 2) {
              if (element.action === 'copy') {
                return (
                  <CopyToClipboard
                    text={window.location.href}
                    key={element.label}
                  >
                    <Button
                      className="idfyButton"
                      color="primary"
                      variant={index % 2 === 0 ? 'contained' : 'outlined'}
                      key={element.label}
                      onClick={(): void => {
                        actionCallback && actionCallback(element.action);
                      }}
                    >
                      {element.imagePath && (
                        <img
                          src={`/images/${element.imagePath}`}
                          alt={element.imagePath}
                          style={{ width: '20px', marginRight: '10px' }}
                        />
                      )}
                      {element.label}
                    </Button>
                  </CopyToClipboard>
                );
              }
              return (
                <Button
                  className="idfyButton"
                  color="primary"
                  variant={index % 2 === 0 ? 'contained' : 'outlined'}
                  key={element.label}
                  onClick={(): void => {
                    this.onClick(element.action);
                  }}
                  disabled={element.loader}
                >
                  {element.imagePath && (
                    <img
                      src={`/images/${element.imagePath}`}
                      alt={element.imagePath}
                      style={{
                        width: '20px',
                        marginRight: '10px',
                      }}
                    />
                  )}

                  {element.loader ? (
                    <CircularProgress className="permission-circular-progress" />
                  ) : (
                    <Typography variant="button">{element.label}</Typography>
                  )}
                </Button>
              );
            }
            return null;
          })}
        </div>
      </div>
    );
  }

  render(): JSX.Element {
    const {
      buttonConfig,
      showHeader,
      headerConfig,
      titleConfig,
      bottomNote,
      handleGoBack,
    } = this.props;
    let divHeight = 'calc(100% - 11.5em)';
    if (buttonConfig && buttonConfig.length === 1 && !showHeader) {
      divHeight = 'calc(100% - 7em)';
    }
    if (buttonConfig && buttonConfig.length === 2) {
      divHeight = 'calc(100% - 15.5em)';
    }

    return (
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        {showHeader && headerConfig && (
          <AppBar
            handleBack={handleGoBack}
            UIConfig={{
              appBackground: headerConfig.appBackground,
              isErrorScreen: headerConfig.isErrorScreen,
              displayBackButton: headerConfig.displayBackButton,
              displayExitButton: headerConfig.displayExitButton,
            }}
          />
        )}
        <div
          className="page-content-holder"
          style={{
            height: divHeight,
            justifyContent: 'center',
          }}
        >
          {this.getTitle(titleConfig.titleSvg)}
        </div>
        {bottomNote && (
          <div className="note-div">
            <Typography
              dangerouslySetInnerHTML={createMarkup(bottomNote)}
              className="step"
            />
          </div>
        )}
        {buttonConfig && this.getFooter(buttonConfig)}
      </div>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => ({
  redirectUri: state.RedirectUri,
  headerDetails: state.HeaderDetails,
});

export default connect<StateProps, DispatchProps, OwnProps, AppState>(
  mapStateToProps,
  {
    ClearOverlayAction,
  },
)(withTranslation()(SimpleOverlay));
