import React from 'react';
import '../LoaderOverlay/loaderOverlay.css';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Button, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { ClearRedirectingOverlayAction } from '../../store/Error/actions';
import { AppState } from '../../store';
import './RedirectingLoader.css';
import HeaderComponent from '../Header';
import { WithTranslation, withTranslation } from 'react-i18next';
import { log, LogLevel } from '../../helpers/loggerWrapper';
import { DataService } from '../../services/DataService';
import { History } from 'history';
interface DispatchProps {
  ClearRedirectingOverlayAction: typeof ClearRedirectingOverlayAction;
}

interface OwnProps {
  text: string;
  IsWaitingForSubmission: boolean;
  showBackButton: boolean;
  showHeader: boolean;
  handleClick?: () => void;
  history: History;
}

interface Props extends OwnProps, DispatchProps {}

type CombinedProps = Props & WithTranslation;
/**
 * Component for Loader
 */
class RedirectingLoaderComponent extends React.Component<CombinedProps> {
  constructor(props: CombinedProps) {
    super(props);
    this.onBackButtonClick = this.onBackButtonClick.bind(this);
  }

  onBackButtonClick(): void {
    const { ClearRedirectingOverlayAction, handleClick } = this.props;
    log(LogLevel.Info, {
      serviceCategory: 'Capture',
      service: 'DocumentFetcherService',
      eventType: 'Clicked',
      eventName: 'Canceled',
      component: 'RedirectingLoaderComponent',
      eventSource: 'onBackButtonClick',
    });
    DataService.removeAllDocumentTimers();
    ClearRedirectingOverlayAction();
    if (handleClick) handleClick();
  }

  render(): JSX.Element {
    const { showBackButton, showHeader, IsWaitingForSubmission, text } =
      this.props;
    let divHeight = 'calc(100% - 11.5em)';
    if (showBackButton && !showHeader) {
      divHeight = 'calc(100% - 7em)';
    }
    return (
      <div className="redirecting-loader-container">
        {showHeader && (
          <div
            style={{
              width: '100%',
            }}
          >
            <HeaderComponent history={this.props.history} />
          </div>
        )}
        <div
          className="page-content-holder"
          style={{
            height: divHeight,
            justifyContent: 'center',
          }}
        >
          <div className="text-align-center">
            <CircularProgress />
            {IsWaitingForSubmission && (
              <Typography className="waiting-text">{text}</Typography>
            )}
          </div>
        </div>
        {showBackButton && (
          <div className="proceedButton" style={{ width: '100%' }}>
            <div className="buttonGroup">
              <Button
                onClick={this.onBackButtonClick}
                variant="contained"
                color="primary"
                className="idfyButton"
              >
                <Typography variant="button">
                  {this.props.t('GO_BACK_BUTTON')}
                </Typography>
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default connect<{}, DispatchProps, OwnProps, AppState>(null, {
  ClearRedirectingOverlayAction,
})(withTranslation()(RedirectingLoaderComponent));
