import {
  ILanguageTranslationState,
  LanguageTranslationActionTypes,
  SET_LOCALE,
  FETCH_TRANSLATIONS_PENDING,
  FETCH_TRANSLATIONS_FULFILLED,
  FETCH_TRANSLATIONS_REJECTED,
  SET_AVAILABLE_LOCALES,
} from './types';

const initialState: ILanguageTranslationState = {
  availableLocales: [],
  translations: {},
  currentLocale: 'en',
  loading: false,
  error: null,
};

// Reducer
const languageTranslationReducer = (
  state = initialState,
  action: LanguageTranslationActionTypes,
): ILanguageTranslationState => {
  switch (action.type) {
    case SET_LOCALE:
      return {
        ...state,
        currentLocale: action.payload,
      };

    case SET_AVAILABLE_LOCALES:
      return {
        ...state,
        availableLocales: action.payload,
      };

    case FETCH_TRANSLATIONS_PENDING:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_TRANSLATIONS_FULFILLED:
      return {
        ...state,
        loading: false,
        translations: {
          ...state.translations,
          [action.payload.locale]: action.payload.translations,
        },
      };

    case FETCH_TRANSLATIONS_REJECTED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default languageTranslationReducer;
