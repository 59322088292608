// src/store/language-translation/types.ts

// Interface for a single translation object
export interface ILanguageTranslation {
  [templateId: string]: { [key: string]: string };
}

// Interface for the language translation state
export interface ILanguageTranslationState {
  availableLocales: string[];
  translations: {
    [locale: string]: ILanguageTranslation;
  };
  currentLocale: string;
  loading: boolean;
  error: string | null;
}

// Action types constants
export const SET_LOCALE = 'translations/SET_LOCALE';
export const SET_AVAILABLE_LOCALES = 'translations/SET_AVAILABLE_LOCALES';
export const FETCH_TRANSLATIONS_PENDING = 'translations/FETCH_PENDING';
export const FETCH_TRANSLATIONS_FULFILLED = 'translations/FETCH_FULFILLED';
export const FETCH_TRANSLATIONS_REJECTED = 'translations/FETCH_REJECTED';

// Action interfaces
export interface SetLocaleAction {
  type: typeof SET_LOCALE;
  payload: string;
}

export interface SetAvailableLocales {
  type: typeof SET_AVAILABLE_LOCALES;
  payload: string[];
}

export interface FetchTranslationsPendingAction {
  type: typeof FETCH_TRANSLATIONS_PENDING;
}

export interface FetchTranslationsFulfilledAction {
  type: typeof FETCH_TRANSLATIONS_FULFILLED;
  payload: {
    locale: string;
    translations: ILanguageTranslation;
  };
}

export interface FetchTranslationsRejectedAction {
  type: typeof FETCH_TRANSLATIONS_REJECTED;
  payload: string;
}

// Union type for all actions
export type LanguageTranslationActionTypes =
  | SetLocaleAction
  | SetAvailableLocales
  | FetchTranslationsPendingAction
  | FetchTranslationsFulfilledAction
  | FetchTranslationsRejectedAction;
