import { SaveAVKycPageConfigActions, AVKycPageData } from './types';
import {
  SAVE_AGENT_DISPOSITION,
  SAVE_AVKYC_PAGE_CONFIG,
  SAVE_AVKYC_STATUS,
  SAVE_UPLOAD_PROGRESS,
} from '../../../helpers/actionTypes';
import {
  NO_AGENTS_MESSAGE_ASSIGNED_USER,
  TRY_AGAIN_MESSAGE_ASSIGNED_USER,
} from '../../../helpers/constants';

export const avPageConfigInitialState: AVKycPageData = {
  task: {
    key: '',
    request_uid: '',
    status: '',
    task_type_key: '',
  },
  scheduling: {
    enabled: false,
    slots_available: false,
    start_date: '',
    end_date: '',
    booking_details: {
      reference_id: '',
    },
  },
  assignedUser: false,
  max_user_outgoing_bitrate: undefined,
  attempts: {
    last_attempt: false,
    attempts_exhausted: false,
  },
  network_check_config: {
    enabled: false,
    timeout: 60000,
    checks: {
      room_join: false,
    },
    health_check_failure_rules: null,
  },
  high_res_screenshot: false,
  unavailable_agent_config: {
    title: NO_AGENTS_MESSAGE_ASSIGNED_USER,
    text: TRY_AGAIN_MESSAGE_ASSIGNED_USER,
  },
  video_overlay: {
    enabled: false,
  },
  allow_agent_customer_camera_control: {
    enabled: false,
    customer_consent_required: false,
  },
  agentDisposition: {},
  is_location_required: true,
  uploadProgress: 0,
};

export function SaveAVKycPageConfigReducer(
  state = avPageConfigInitialState,
  action: SaveAVKycPageConfigActions,
): AVKycPageData {
  switch (action.type) {
    case SAVE_AVKYC_PAGE_CONFIG:
      return {
        ...state,
        ...action.payload,
      };
    case SAVE_AVKYC_STATUS:
      return {
        ...state,
        task: {
          ...state.task,
          status: action.payload.status,
        },
      };
    case SAVE_AGENT_DISPOSITION:
      return {
        ...state,
        agentDisposition: {
          ...state.agentDisposition,
          od_payload: action.payload.od_payload,
        },
      };
    case SAVE_UPLOAD_PROGRESS:
      return {
        ...state,
        uploadProgress: action.payload.progress,
      };
    default:
      return state;
  }
}
