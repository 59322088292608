// src/store/language-translation/actions.ts
import axios from 'axios';
import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  SET_LOCALE,
  FETCH_TRANSLATIONS_PENDING,
  FETCH_TRANSLATIONS_FULFILLED,
  FETCH_TRANSLATIONS_REJECTED,
  ILanguageTranslation,
  LanguageTranslationActionTypes,
  SET_AVAILABLE_LOCALES,
} from './types';
import { AppState } from '../index';
import { API_SERVER_URI } from '../../helpers/envconfig';
import RealUserMonitoring from '../../helpers/real-user-monitoring';

// API client
const apiClient = axios.create({
  baseURL: `${API_SERVER_URI}/translations`,
});

// Action creator for setting locale
export const setLocaleLanguage = (
  locale: string,
): LanguageTranslationActionTypes => ({
  type: SET_LOCALE,
  payload: locale,
});

// Action creator for setting available locales
export const setAvailableLocales = (
  locales: string[],
): LanguageTranslationActionTypes => ({
  type: SET_AVAILABLE_LOCALES,
  payload: locales,
});

// Thunk action creator for fetching translations
export const fetchLanguageTranslations = (
  packageId: string,
  locale: string,
  captureToken: string,
): ThunkAction<Promise<void>, AppState, unknown, Action<string>> => {
  return async (dispatch, getState) => {
    try {
      // Check if we already have translations for this locale
      const state = getState();
      if (state.LanguageTranslationState.translations[locale]) {
        dispatch({
          type: FETCH_TRANSLATIONS_FULFILLED,
          payload: {
            locale,
            translations: state.LanguageTranslationState.translations[locale],
          },
        });
        return;
      }
      // Start loading
      dispatch({ type: FETCH_TRANSLATIONS_PENDING });
      // Make API request
      const response = await apiClient.get<ILanguageTranslation>(
        `/${packageId}/${locale}/active`,
        {
          params: { t: captureToken },
        },
      );
      if (response?.status !== 200 || !response?.data?.data) {
        RealUserMonitoring.logError(response);
        throw new Error('Invalid status or response');
      }
      // Success
      dispatch({
        type: FETCH_TRANSLATIONS_FULFILLED,
        payload: { locale, translations: response?.data?.data },
      });
    } catch (error) {
      RealUserMonitoring.logError(error);
      // Handle errors
      let errorMessage = 'Unknown error occurred';
      if (axios.isAxiosError(error)) {
        errorMessage = error.response?.data?.message || error.message;
      } else if (error instanceof Error) {
        errorMessage = error.message;
      }
      dispatch({
        type: FETCH_TRANSLATIONS_REJECTED,
        payload: errorMessage,
      });
    }
  };
};
