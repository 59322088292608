import React from 'react';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {
  AppBar as MuiAppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
} from '@material-ui/core';
import { connect } from 'react-redux';
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import { WithTranslation, withTranslation } from 'react-i18next';
import { PaletteOptions, ThemeConfig } from '../../store/ThemeConfig/types';
import { AppState } from '../../store';
import { ExitUri } from '../../store/RedirectUri/types';
import { log, LogLevel } from '../../helpers/loggerWrapper';
import { DataService } from '../../services/DataService';
import { ERROR_TYPE_INVALID_LINK } from '../../helpers/constants';
import { redirectTo } from '../../helpers';

interface StateProps {
  themeConfig: ThemeConfig;
  exitUri: ExitUri;
}

export interface OwnProps {
  UIConfig: {
    displayBackButton: boolean;
    isErrorScreen: boolean;
    appBackground?: string;
    backButtonColor?: string;
    title?: string;
    titleColor?: string;
    displayExitButton?: boolean;
  };
  handleBack?: () => void;
}

interface Props extends OwnProps, StateProps {}

type CombinedProps = Props & WithTranslation;
export class AppBar extends React.Component<CombinedProps> {
  constructor(props: CombinedProps) {
    super(props);
    this.onCloseButtonClick = this.onCloseButtonClick.bind(this);
  }

  async onCloseButtonClick(): Promise<void> {
    const { exitUri } = this.props;
    log(LogLevel.Info, {
      serviceCategory: 'Capture',
      service: 'ExitJourney',
      eventName: 'CloseClicked',
      eventType: 'Clicked',
      eventSource: 'onCloseButtonClick',
      component: 'AppBar',
    });
    // eslint-disable-next-line
    if (window.confirm(this.props.t('CONFIRM_EXIT_MESSAGE'))) {
      DataService.checkRedirectUrl()
        .then((resp) => {
          if (resp.data.valid) {
            log(LogLevel.Info, {
              serviceCategory: 'Capture',
              service: 'ExitJourney',
              eventName: 'YesClicked',
              eventType: 'Clicked',
              eventSource: 'onCloseButtonClick',
              component: 'AppBar',
            });
            redirectTo(exitUri.exit_uri);
          } else {
            DataService.redirectToInvalidLink(resp.data.error);
            return;
          }
        })
        .catch((error) => {
          DataService.RaiseError(new Error('INVALID_REDIRECT_URL'), {
            errorServiceCategory: 'Capture',
            errorService: 'RedirectUrl',
            eventType: 'Exception',
            errorType: ERROR_TYPE_INVALID_LINK,
            errorSource: 'fetchCaptureDetails',
            component: 'IndvCaptureComponent',
            exceptionName: 'InvalidRedirectUrl',
            exceptionDescription: 'Redirect / Exit URL is not valid',
            metaData: {
              error,
            },
          });
          return;
        });
      log(LogLevel.Info, {
        serviceCategory: 'Capture',
        service: 'ExitJourney',
        eventName: 'YesClicked',
        eventType: 'Clicked',
        eventSource: 'onCloseButtonClick',
        component: 'AppBar',
      });
      redirectTo(exitUri.exit_uri);
    } else {
      log(LogLevel.Info, {
        serviceCategory: 'Capture',
        service: 'ExitJourney',
        eventName: 'NoClicked',
        eventType: 'Clicked',
        eventSource: 'onCloseButtonClick',
        component: 'AppBar',
      });
    }
  }

  getThemeColors(colorType: string): string {
    const { themeConfig, UIConfig } = this.props;
    let color;
    const palette = themeConfig.palette as PaletteOptions;
    if (colorType === 'textColor') {
      if (UIConfig.titleColor) {
        color = UIConfig.titleColor;
      } else if (themeConfig.custom_header && themeConfig.custom_header.color) {
        color = themeConfig.custom_header.color;
      } else {
        color = palette.secondary.contrastText;
      }
    } else if (colorType === 'backgroundColor') {
      if (UIConfig.appBackground) {
        color = UIConfig.appBackground;
      } else if (
        themeConfig.custom_header &&
        themeConfig.custom_header.backgroundColor
      ) {
        color = themeConfig.custom_header.backgroundColor;
      } else {
        color = palette.secondary.main;
      }
    } else if (UIConfig.backButtonColor) {
      color = UIConfig.backButtonColor;
    } else if (themeConfig.custom_header && themeConfig.custom_header.color) {
      color = themeConfig.custom_header.color;
    } else {
      color = palette.info.main;
    }
    return color;
  }

  showExitButton(): boolean {
    const { exitUri, UIConfig } = this.props;
    return (
      !!exitUri.exit_uri &&
      typeof UIConfig.displayExitButton === 'boolean' &&
      UIConfig.displayExitButton
    );
  }

  render(): JSX.Element {
    const { UIConfig, handleBack } = this.props;
    return (
      <MuiAppBar
        className="app-bar"
        style={{
          background: this.getThemeColors('backgroundColor'),
          position: UIConfig.isErrorScreen ? 'relative' : 'absolute',
          padding: UIConfig.isErrorScreen ? '6.5px' : '10px',
        }}
      >
        <Toolbar>
          <>
            {UIConfig.displayBackButton ? (
              <IconButton
                onClick={(): void => {
                  handleBack && handleBack();
                }}
                edge="start"
                className="back-icon"
                aria-label="menu"
                style={{
                  color: this.getThemeColors('iconColor'),
                }}
              >
                <ArrowBackIosIcon />
              </IconButton>
            ) : (
              <></>
            )}
            {UIConfig.title ? (
              <Typography
                variant="h5"
                style={{
                  color: this.getThemeColors('textColor'),
                  marginRight: '3%',
                }}
              >
                {UIConfig.title}
              </Typography>
            ) : (
              <></>
            )}
            {this.showExitButton() ? (
              <div className="header-close-button">
                <Button
                  className="header-close-button-styling"
                  onClick={this.onCloseButtonClick}
                  disabled={false}
                >
                  <CloseSharpIcon
                    style={{ color: this.getThemeColors('iconColor') }}
                    fontSize="large"
                  />
                </Button>
              </div>
            ) : (
              <></>
            )}
          </>
        </Toolbar>
      </MuiAppBar>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => ({
  themeConfig: state.ThemeConfig,
  exitUri: state.ExitUri,
});

export default connect<StateProps, {}, OwnProps, AppState>(mapStateToProps)(
  withTranslation()(AppBar),
);
