import { ChangeEvent, useEffect, useState } from 'react';
import {
  MenuItem,
  ListItemText,
  Select,
  FormControl,
  ListItemIcon,
  makeStyles,
  Typography,
} from '@material-ui/core';
import TranslateIcon from '@material-ui/icons/Translate';
import React from 'react';
import { AppState } from '../../store';
import { connect } from 'react-redux';
import { DataService } from '../../services/DataService';
import theme from '../../theme';
import { withTranslation, WithTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  select: {
    width: '144px',
    maxWidth: '144px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    height: '48px',
    backgroundColor: theme().palette.secondary.main,
  },
  menuPaper: {
    backgroundColor: theme().palette.secondary.main,
  },
  outlinedInput: {
    paddingLeft: '8px !important',
    paddingRight: '24px !important',
  },
  menuItem: {
    minHeight: '24px',
    padding: '4px 16px',
  },
  listItemIcon: {
    minWidth: 24,
    alignItems: 'center',
  },
  listItemText: {
    color: theme().palette.secondary.contrastText,
  },
}));

const supportedLanguagesMap: { [code: string]: { label: string } } = {
  bn: { label: 'বাংলা' }, // Bengali
  ar: { label: 'العربية' }, // Arabic
  en: { label: 'English' }, // English
  es: { label: 'Español' }, // Spanish
  hi: { label: 'हिन्दी' }, // Hindi
  id: { label: 'Bahasa Indonesia' }, // Indonesian
  mr: { label: 'मराठी' }, // Marathi
  tl: { label: 'Tagalog' }, // Tagalog
  ur: { label: 'اردو' }, // Urdu
  gu: { label: 'ગુજરાતી' }, // Gujarati
  ta: { label: 'தமிழ்' }, // Tamil
  te: { label: 'తెలుగు' }, // Telugu
};

interface StateProps {
  availableLocales: string[];
  currentLocale: string;
}

type CombinedProps = StateProps & WithTranslation;

const DisplayLanguageSelector = (props: CombinedProps) => {
  const classes = useStyles();

  const [selectedLocale, setSelectedLocale] = useState<string | null>(null);

  const handleChange = (e: ChangeEvent<{ name?: string; value: unknown }>) => {
    const locale = e.target.value as string;
    setSelectedLocale(locale);
    DataService.updateDisplayLangugae(locale);
  };

  useEffect(() => {
    if (props?.currentLocale && props.availableLocales.length) {
      document.documentElement.style.setProperty(
        '--language-dropdown-height',
        '64px',
      );
      setSelectedLocale(props.currentLocale);
    }
  }, [props?.availableLocales]);

  if (!props?.availableLocales?.length) {
    document.documentElement.style.setProperty(
      '--language-dropdown-height',
      '0px',
    );
    return null;
  }

  return (
    <div id={'language-dropdown-component'}>
      <Typography
        style={{
          textAlign: 'left',
          fontSize: 'medium',
        }}
      >
        {props.t('LANGUAGE_SELECT_LABEL')}
      </Typography>
      <FormControl variant="outlined" className={classes.outlinedInput}>
        <Select
          value={selectedLocale ?? ''}
          onChange={handleChange}
          displayEmpty
          className={classes.select + ' select-dropdown'}
          MenuProps={{
            classes: { paper: classes.menuPaper },
          }}
        >
          {/* Default placeholder with only icon and blank label */}
          <MenuItem value="" disabled className={classes.menuItem}>
            <ListItemIcon className={classes.listItemIcon}>
              <TranslateIcon fontSize="small" color="primary" />
            </ListItemIcon>
            <ListItemText primary={'Select'} className={classes.listItemText} />
          </MenuItem>
          {props.availableLocales.map((localeCode) => (
            <MenuItem
              key={localeCode}
              value={localeCode}
              className={classes.menuItem}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <TranslateIcon fontSize="small" color="primary" />
              </ListItemIcon>
              <ListItemText
                primary={supportedLanguagesMap[localeCode].label ?? localeCode}
                className={classes.listItemText}
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  availableLocales: state.LanguageTranslationState.availableLocales,
  currentLocale: state.LanguageTranslationState.currentLocale,
});

export default connect(mapStateToProps)(
  withTranslation()(DisplayLanguageSelector),
);
