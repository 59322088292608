import React, { useEffect } from 'react';
import Lottie from 'react-lottie';
import * as animationData from './shield.json';

import './LandingPage.css';
import { toggleLanguageDropdownVisibility } from '../../helpers';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

function RedirectComponent() {
  useEffect(() => {
    toggleLanguageDropdownVisibility(false);
    return () => {
      toggleLanguageDropdownVisibility(true);
    };
  });
  return (
    <div className="landing-page-wrapper">
      <div>
        <Lottie options={defaultOptions} height={300} width={300} />
        <h2>Hang Tight !</h2>
        <div className="redirecting-help-text">
          You’re being redirected to a secure page for verification.
        </div>
        <div>This may take upto 05 seconds</div>
      </div>

      <div className="foot-note">
        <div className="secured">
          <img src="/images/lock.svg" alt="lock_image" />
        </div>
        <div>Your information is safe with us</div>
      </div>
    </div>
  );
}

export default RedirectComponent;
