import React from 'react';
import { DataService } from '../../services/DataService';
import { logPageVisit } from '../../helpers/loggerWrapper';
import { getTatStartRef } from '../../helpers';
import SimpleOverlay from '../SimpleOverlay';
import { WithTranslation, withTranslation } from 'react-i18next';
import ClickTracker from '../../helpers/ui-click-event';

type CombinedProps = WithTranslation;

class PoorConnectionComponent extends React.Component<CombinedProps> {
  loggerStartTime: number = getTatStartRef();

  componentDidMount(): void {
    ClickTracker?.setComponentName?.('PoorConnectionComponent');
    logPageVisit('PoorConnectivity', 'PoorConnectionComponent');
    DataService.EndCommunications().then(
      // eslint-disable-next-line
      () => {},
      // eslint-disable-next-line
      () => {},
    );
  }

  componentWillUnmount(): void {
    ClickTracker?.unsetComponentName?.();
  }

  render(): JSX.Element {
    return (
      <SimpleOverlay
        showHeader
        titleConfig={{
          primaryText: this.props.t('POOR_CONNECTION'),
          secondaryText: this.props.t('POOR_CONNECTION_MESSAGE'),
          tertiaryText: this.props.t('ERROR_REST_ASSURED_MESSAGE'),
          titleSvg: 'somethingWentWrong',
        }}
        showCrossButton={false}
      />
    );
  }
}

export default withTranslation()(PoorConnectionComponent);
