import React from 'react';
import { Button, Typography, CircularProgress } from '@material-ui/core';
import CopyToClipboard from 'react-copy-to-clipboard';
import AppBar from '../AppBar';
import { createMarkup } from '../../helpers';
import './InstructionBasedOverlay.css';
import theme from '../../theme';
import { DataService } from '../../services/DataService';
import { translateNumbers } from '../../i18n/i18n';
import { WithTranslation, withTranslation } from 'react-i18next';

export interface InstructionConfig {
  instruction: string;
  svg?: string;
}
const copy = 'copy';
const retry = 'retry';
const open = 'open';
const goBack = 'goBack';
const leave_journey = 'leave_journey';

export type buttonActionTypes =
  | typeof copy
  | typeof retry
  | typeof open
  | typeof goBack
  | typeof leave_journey;

export interface ButtonConfig {
  label: string;
  action: buttonActionTypes;
  text?: string;
  imagePath?: string;
  loader?: boolean;
}

interface Props {
  showHeader: boolean;
  titleConfig: {
    primaryText: string;
    secondaryText: string;
    titleSvg: string;
  };
  headerConfig?: {
    appBackground: string;
    isErrorScreen: boolean;
    displayBackButton: boolean;
    displayExitButton?: boolean;
  };
  instructions: InstructionConfig[];
  buttonConfig?: ButtonConfig[];
  bottomNote?: string;
  handleGoBack?: () => void;
  actionCallback: (actionType: buttonActionTypes) => void;
}
type CombinedProps = Props & WithTranslation;

class InstructionBasedOverlay extends React.Component<CombinedProps> {
  constructor(props: CombinedProps) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick(action: buttonActionTypes): void {
    const { actionCallback } = this.props;
    actionCallback(action);
  }

  getTitle(svg: string): JSX.Element {
    const { showHeader, titleConfig } = this.props;
    return (
      <div
        className="primary-title-div"
        style={{ marginTop: !showHeader ? '16%' : '0%', marginBottom: '4%' }}
      >
        <div>
          <Typography variant="h6" className="primary-text">
            {titleConfig.primaryText}
          </Typography>
        </div>
        <div>{DataService.getSvg(svg)}</div>
      </div>
    );
  }

  getSubtitle(): JSX.Element {
    const { titleConfig } = this.props;
    return (
      <div style={{ marginBottom: '4%', display: 'flex' }}>
        <Typography
          variant="subtitle2"
          className="subtitle"
          style={{ opacity: '0.8' }}
        >
          {titleConfig.secondaryText}
        </Typography>
      </div>
    );
  }

  getFooter(buttons: ButtonConfig[]): JSX.Element {
    const { actionCallback } = this.props;
    return (
      <div className="proceedButton">
        {buttons.map((element: ButtonConfig, index: number) => {
          if (index < 2) {
            if (element.action === 'copy') {
              return (
                <CopyToClipboard
                  text={element.text ? element.text : ''}
                  key={element.label}
                >
                  <Button
                    className="idfyButton"
                    color="primary"
                    variant={index % 2 === 0 ? 'contained' : 'outlined'}
                    key={element.label}
                    onClick={(): void => {
                      actionCallback(element.action);
                    }}
                    style={{ marginBottom: '15px' }}
                  >
                    {element.imagePath && (
                      <img
                        src={`/images/${element.imagePath}`}
                        alt={element.imagePath}
                        style={{ width: '20px', marginRight: '10px' }}
                      />
                    )}
                    {element.label}
                  </Button>
                </CopyToClipboard>
              );
            }
            return (
              <Button
                className="idfyButton"
                color="primary"
                variant={index % 2 === 0 ? 'contained' : 'outlined'}
                key={element.label}
                onClick={(): void => {
                  this.onClick(element.action);
                }}
                disabled={element.loader}
                style={{ marginBottom: '15px' }}
              >
                {element.imagePath && (
                  <img
                    src={`/images/${element.imagePath}`}
                    alt={element.imagePath}
                    style={{
                      width: '20px',
                      marginRight: '10px',
                    }}
                  />
                )}

                {element.loader ? (
                  <CircularProgress className="permission-circular-progress" />
                ) : (
                  element.label
                )}
              </Button>
            );
          }
          return null;
        })}
      </div>
    );
  }

  render(): JSX.Element {
    const {
      showHeader,
      buttonConfig,
      headerConfig,
      handleGoBack,
      titleConfig,
      instructions,
      bottomNote,
    } = this.props;
    let divHeight = 'calc(100% - 11.5em)';
    if (buttonConfig && buttonConfig.length === 1 && !showHeader) {
      divHeight = 'calc(100% - 7em)';
    }

    return (
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        {showHeader && headerConfig && handleGoBack && (
          <AppBar
            handleBack={handleGoBack}
            UIConfig={{
              appBackground: headerConfig.appBackground,
              isErrorScreen: headerConfig.isErrorScreen,
              displayBackButton: headerConfig.displayBackButton,
              displayExitButton: headerConfig.displayExitButton,
            }}
          />
        )}
        <div
          className="page-content-holder"
          style={{
            height: divHeight,
            color: theme().palette.secondary.contrastText,
          }}
        >
          {this.getTitle(titleConfig.titleSvg)}
          {this.getSubtitle()}
          <div className="instruction-set" style={{ opacity: '0.8' }}>
            <div className="instruction">
              <Typography variant="h6">
                {this.props.t('INSTRUCTION_STEPS')}
              </Typography>
            </div>
            {instructions.map((element: InstructionConfig, index: number) => (
              <div key={element.instruction}>
                <div className="instruction">
                  <Typography variant="body2">
                    {`${translateNumbers(index + 1)}. `}
                  </Typography>
                  <Typography
                    dangerouslySetInnerHTML={createMarkup(element.instruction)}
                    variant="body2"
                    style={{ width: '90%' }}
                  />
                </div>
                {element.svg && (
                  <div className="instruction">
                    <img
                      src={`/images/${element.svg}`}
                      alt={element.svg}
                      style={{ height: '3em', maxWidth: '100%' }}
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
          {bottomNote && (
            <div className="note-div">
              <Typography
                variant="caption"
                dangerouslySetInnerHTML={createMarkup(bottomNote)}
                className="step"
              />
            </div>
          )}
        </div>
        {buttonConfig && this.getFooter(buttonConfig)}
      </div>
    );
  }
}

export default withTranslation()(InstructionBasedOverlay);
